import { FC } from 'react';

import classes from './ModalTitle.module.scss';

interface IModalTitle {
  title: string;
  style?: any;
}

export const ModalTitle: FC<IModalTitle> = ({ title, style = {} }) => {
  return (
    <div className={classes.title__block} style={style}>
      <h1 className={classes.title}>{title}</h1>
    </div>
  );
};
