import { FC } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const StyledDrawerHeader = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '1.875rem',
  '& > svg': {
    cursor: 'pointer',
  },
});

interface IDrawerHeaderProps {
  className?: string;
  children?: React.ReactNode;
}

export const DrawerHeader: FC<IDrawerHeaderProps> = ({
  className,
  children,
}) => {
  return (
    <StyledDrawerHeader className={className}>{children}</StyledDrawerHeader>
  );
};
