import { FC } from 'react';

import classes from './PageTitle.module.scss';

interface IPageTitle {
  title: string;
}

export const PageTitle: FC<IPageTitle> = ({ title }) => {
  return (
    <div className={classes.title__block}>
      <h1 className={classes.title}>{title}</h1>
    </div>
  );
};
